import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import { Message } from 'element-ui'
import router from '@/router'

const instance = axios.create({
    baseURL: process.env.VUE_APP_URL,
    // baseURL: 'https://unicom-mapi.ikanke.cn',
    timeout: 60000,
})

// 添加请求拦截器
instance.interceptors.request.use(
    function(config) {
        // 在发送请求之前做些什么
        // console.log(config)

        const noTokenUrl = ['/v1/employee/list-page22222']

        const token = sessionStorage.getItem('userToken')
        if (token && !noTokenUrl.includes(config.url)) {
            config.headers.token = token
        }

        return config
    },
    function(error) {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)

// 添加响应拦截器
instance.interceptors.response.use(
    function(res) {
        // 不需要额外处理返回值的URL
        const unHandleUrl = [
            '/v1/visit-log/download', //访客记录-导出
            '/v1/place/list/download', //单位管理-导出
            '/v1/push/stat/download', //数据统计-导出
            '/v1/device/list/download', //设备管理-导出
            '/v1/follow/list/download', //本地关注人员-导出
            '/v1/staff/list/download', //员工管理-导出
            '/v1/staff/logList/download', //考勤记录-导出
            '/v1/staff/download_headpic', //员工管理-员工照片
            '/v1/staff/reg_qr_pic', //员工管理-采集二维码
            '/v1/visit/reg_qr_pic', //员工管理-采集二维码
            '/v1/visit/list/download',//访客管理-导出
            '/v1/door/list-page-download',//点位管理-导出
        ]

        if (unHandleUrl.includes(res.config.url)) {
            return res
        }

        if (res.data.status === 200||res.data.status === 901) {
            return res
        } else if ([600, 602].includes(res.data.status)) {
            Message({
                message: res.data.message,
                type: 'error',
            })
            router.push('/login')
        } else Message.error(res.data.message)
    },
    function(error) {
        // 对响应错误做点什么
        return Promise.reject(error)
    }
)

Vue.prototype.$axios = axios
Vue.prototype.$instance = instance
Vue.prototype.$qs = qs
