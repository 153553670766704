import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import '@/utils/element-variables.scss'
import './plugins/axios/index'
import Menu from '@/utils/menu-list'
import md5 from 'js-md5'
import './utils/header.js'

Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.$md5 = md5

Vue.prototype.$currMenuList = (user = '1') => {
    return Menu(String(user)).currMenuList
}

new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app')
