// 所有菜单数据
const menuList = [
    {
        path: '/content/home',
        name: '数据看版',
        // 属于
        belong: [],
    },
    {
        path: '/content/company-admin',
        name: '场所管理',
        // 属于
        belong: ['1', '4'],
    },
    {
        path: '/content/record',
        name: '访客记录',
        // 属于
        belong: [],
    },
    {
        path: '/content/check-record',
        name: '考勤记录',
        // 属于
        belong: ['2'],
    },
    {
        path: '/content/department-admin',
        name: '组织管理',
        // 属于
        belong: ['2'],
    },
    {
        path: '/content/staff-admin',
        name: '员工管理',
        // 属于
        belong: ['2'],
    },
    {
        path: '/content/visitor-admin',
        name: '访客管理',
        // 属于
        belong: ['2'],
    },
    {
        path: '/content/equipment-record',
        name: '设备下发记录',
        // 属于
        belong: ['2'],
    },
    {
        path: '/content/gatekeeper-admin',
        name: '点位管理',
        // 属于
        belong: ['1', '4'],
    },
    {
        path: '/content/personnel-admin',
        name: '本地关注人员管理',
        // 属于
        belong: ['1', '4'],
    },
    {
        path: '/content/classification-admin',
        name: '本地关注人员分类管理',
        // 属于
        belong: ['1', '4'],
    },
    {
        path: '/content/equipment-admin',
        name: '设备管理',
        // 属于
        belong: ['1'],
    },
    {
        path: '/content/commuting-equipment',
        name: '通勤设备管理',
        // 属于
        belong: ['1'],
    },
    {
        path: '/content/visit-state',
        name: '来访统计',
        // 属于
        belong: ['1'],
    },
    {
        path: '/content/account-admin',
        name: '账号管理',
        // 属于
        belong: ['1'],
    },
    {
        path: '/content/agent-admin',
        name: '代理商管理',
        // 属于
        belong: ['1'],
    },
    {
        path: '/content/requestLog',
        name: '请求日志',
        // 属于
        belong: ['1'],
    },
    {
        path: '/content/passageway-admin',
        name: '通道管理',
        // 属于
        belong: ['1'],
    },
    {
        path: '/content/model-admin',
        name: '设备型号管理',
        // 属于
        belong: ['1'],
    },
    {
        path: '/content/information',
        name: '填报资料',
        // 属于
        belong: [],
    },
    {
        path: '/content/my-password',
        name: '修改密码',
        // 属于
        belong: [],
    },
]

export default (user = '1') => {
    // 1超管；2场所管理员；3区域管理员；4代理商
    // const user = sessionStorage.getItem('userInfo') + ''

    // [1,2,3,'d','r'].includes(1)
    // true||false

    // 当前权限对应的菜单数据
    const currMenuList = []
    // 循环菜单数据
    menuList.map((item) => {
        // 如果菜单数据中的belong有长度
        if (item.belong.length) {
            // 判断简单数组中是否有某个值（返回Boolean值）
            if (item.belong.includes(user)) {
                // 向新的菜单数组添加一个数据
                currMenuList.push(item)
                // console.log('currMenuList: ', currMenuList)
            }
        } else {
            // 如果菜单数据中的belong没有长度，那么直接向新的菜单数组添加一个数据
            currMenuList.push(item)
        }
    })

    return { menuList, currMenuList }
}
// 原菜单数据/筛选后菜单数据
