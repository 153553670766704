import Vue from 'vue'
import VueRouter from 'vue-router'
import Menu from '../utils/menu-list'
import { Message } from 'element-ui'
// import Router from ‘vue-router’
// 解决 VUE报错Uncaught (in promise) NavigationDuplicated:
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch((error) => error)
}

Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: '/login' },
    // 登录
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login.vue'),
    },
    // 公共页面
    {
        path: '/content',
        component: () => import('../views/content/index.vue'),
        children: [
            // 数据看版
            {
                path: 'home',
                name: 'home',
                component: () => import('../views/content/home.vue'),
            },
            // 单位管理
            {
                path: 'company-admin',
                name: 'company-admin',
                component: () => import('../views/content/company-admin.vue'),
            },
            // 访客记录
            {
                path: 'record',
                name: 'record',
                component: () => import('../views/content/record.vue'),
            },
            // 考勤记录
            {
                path: 'check-record',
                name: 'check-record',
                component: () => import('../views/content/check-record.vue'),
            },
            // 组织管理
            {
                path: 'department-admin',
                name: 'department-admin',
                component: () =>
                    import('../views/content/department-admin.vue'),
            },
            // 员工管理
            {
                path: 'staff-admin',
                name: 'staff-admin',
                component: () => import('../views/content/staff-admin.vue'),
            },
            // 访客管理
            {
                path: 'visitor-admin',
                name: 'visitor-admin',
                component: () => import('../views/content/visitor-admin.vue'),
            },
            // 设备下发记录
            {
                path: 'equipment-record',
                name: 'equipment-record',
                component: () => import('../views/content/equipment-record.vue'),
            },
            // 门岗管理
            {
                path: 'gatekeeper-admin',
                name: 'gatekeeper-admin',
                component: () =>
                    import('../views/content/gatekeeper-admin.vue'),
            },
            // 本地关注人员管理
            {
                path: 'personnel-admin',
                name: 'personnel-admin',
                component: () => import('../views/content/personnel-admin.vue'),
            },
            // 本地关注人员分类管理
            {
                path: 'classification-admin',
                name: 'classification-admin',
                component: () =>
                    import('../views/content/classification-admin.vue'),
            },
            // 设备管理
            {
                path: 'equipment-admin',
                name: 'equipment-admin',
                component: () => import('../views/content/equipment-admin.vue'),
            },
            // 通勤设备管理
            {
                path: 'commuting-equipment',
                name: 'commuting-equipment',
                component: () => import('../views/content/commuting-equipment.vue'),
            },
            // 来访统计
            {
                path: 'visit-state',
                name: 'visit-state',
                component: () => import('../views/content/visit-state.vue'),
            },
            // 账号管理
            {
                path: 'account-admin',
                name: 'account-admin',
                component: () => import('../views/content/account-admin.vue'),
            },
            // 代理商管理
            {
                path: 'agent-admin',
                name: 'agent-admin',
                component: () => import('../views/content/agent-admin.vue'),
            },
            // 请求日志
            {
                path: 'requestLog',
                name: 'requestLog',
                component: () => import('../views/content/requestLog.vue'),
            },
            // 通道管理
            {
                path: 'passageway-admin',
                name: 'passageway-admin',
                component: () =>
                    import('../views/content/passageway-admin.vue'),
            },
            // 设备型号管理
            {
                path: 'model-admin',
                name: 'model-admin',
                component: () => import('../views/content/model-admin.vue'),
            },
            // 填报资料
            {
                path: 'information',
                name: 'information',
                component: () => import('../views/content/information.vue'),
            },
            // 修改密码
            {
                path: 'my-password',
                name: 'my-password',
                component: () => import('../views/content/my-password.vue'),
            },
        ],
    },
]

const router = new VueRouter({
    // mode: 'history',
    // base: process.env.BASE_URL,
    routes,
})

// 路由白名单-不需要进行菜单权限判断的路由
const whiteList = ['/login']
// 路由守卫
router.beforeEach((to, from, next) => {
    // 从本地sessionStorage里面获取当前状态，要先存进去
    if (sessionStorage.getItem('myCode') === '666' && to.path !== '/login') {
      if (to.path === '/content/my-password') next()
      else next('/content/my-password')
    //   if (from.path !== '/login') {
        Message({
          showClose: true,
          message: '您当前使用的是默认密码，请修改密码后登录',
          type: 'warning'
        })
    //   }
      return
    }
    if (whiteList.indexOf(to.path) === -1) {
        // 当要去的页面路由不在白名单内时。表明该路由需要进行菜单权限判断

        // ===下面的代码是【菜单权限判断】===
        // 如果有相等值，那么找到它的下标
        let userInfo = sessionStorage.getItem('userInfo')
        const isExist = Menu(String(userInfo)).currMenuList.findIndex(
            (item) => {
                // currMenuList数组中 是否有 需要跳转到的路径
                return item.path === to.path
            }
        )
        // isExist === 0 || isExist === -1

        if (isExist === -1) {
            next('/login')
        } else {
            next()
        }
    } else {
        // 否则，直接跳转到对应的页面
        next()
    }
})

export default router
